<template>
  <v-dialog
    v-model="value"
    :max-width="maxWidth"
    @click:outside="$emit('input', false)"
  >
    <v-card>
      <v-card-title
        class="subtitle-1"
      >
        {{ text }}
      </v-card-title>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="blue darken-1"
          text
          @click="onCancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="onConfirm"
        >
          OK
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    value: { type: Boolean, required: true },
    text: { type: String, required: true },
    maxWidth: { type: Number, required: false, default: 500 },
  },
  methods: {
    onCancel() {
      this.$emit('input', false)
    },
    onConfirm() {
      this.$emit('confirmed')
    }
  }
}
</script>

<style scoped>

</style>