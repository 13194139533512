<template>
  <div class="wrapper">
    <slot v-if="authenticated"
          :admin="admin"
          :supervisor="supervisor"
          :cliente="cliente"
          name="allowed"></slot>
    <slot v-else :login="login" name="forbidden"></slot>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IfAuth",
  computed: mapGetters('auth', ['authenticated', 'supervisor', 'admin', 'cliente']),
  methods: mapActions('auth', ['login'])
}
</script>

<style lang="sass" scoped>
.wrapper
  display: contents
</style>