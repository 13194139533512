<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-toolbar color="primary" flat>
            <v-text-field
              v-model="filter.query"
              :prepend-inner-icon="'mdi-filter-variant'"
              append-icon="mdi-magnify"
              clearable
              hide-details
              placeholder="Aa..."
              solo
              text
              @keyup.enter="fetchRecords(filter)"
              @click:clear="fetchRecords(filter)"
            />
            <v-btn color="white" icon @click="handleRefreshItem">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <if-auth>
              <template v-slot:allowed="{admin}">
                <v-btn v-if="admin" color="white" icon @click="$router.push({name:'admin.client.create'})">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
            </if-auth>
          </v-toolbar>
          <v-card-text>
            <confirmation-dialog 
              v-model="dialogDelete"
              text="¿Estás seguro de que quieres eliminar el cliente?"
              :max-width="400"
              @confirmed="deleteItem"
              @input="closeDialogDelete"
            />
            <confirmation-dialog
              v-model="dialogWebAccess"
              text="¿Estás seguro de que quieres otorgar el acceso a este sitio web al cliente?"
              :max-width="400"
              @confirmed="grantWebAccess"
              @input="closeDialogWebAccess"
            />
            <confirmation-dialog
              v-model="dialogWebRevoke"
              text="¿Estás seguro de que quieres revocar el acceso a este sitio web al cliente?"
              :max-width="400"
              @confirmed="revokeWebAccess"
              @input="closeDialogWebRevoke"
            />
            <v-data-table
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 50],
              }"
              :headers="headers"
              :items="clients"
              :loading="loadingItems"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              disable-sort
            >
              <template v-slot:[`item.name`]="{ item }">
                <router-link :to="{name:'admin.client.view', params:{id: item.id}}">
                  {{ item.name }}
                </router-link>
              </template>
              <template v-slot:[`item.identification`]="{ item }">
                <router-link :to="{name:'admin.client.view', params:{id: item.id}}" class="link" tag="tr">
                  {{ item.identification }}
                </router-link>
              </template>
              <template v-slot:[`item.supervisor.name`]="{ item }">
                <v-chip v-if="item.supervisor" color="primary">
                  {{ item.supervisor.name }}
                </v-chip>
                <div v-else/>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <if-auth>
                  <template v-slot:allowed="{admin}">
                    <div>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs"
                                 class="ma-1"
                                 color="green accent-5"
                                 depressed
                                 fab
                                 x-small
                                 v-on="on"
                                 @click="viewReport(item)">
                            <v-icon color="white" small>mdi-microsoft-excel</v-icon>
                          </v-btn>

                        </template>
                        <span>Reporte de trabajos</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs"
                                 class="ma-1"
                                 color="green accent-5"
                                 depressed
                                 fab
                                 x-small
                                 v-on="on"
                                 @click="viewClient(item)">
                            <v-icon color="white" small>mdi-eye</v-icon>
                          </v-btn>

                        </template>
                        <span>Detalles</span>
                      </v-tooltip>

                      <v-tooltip v-if="admin" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs"
                                 class="ma-1"
                                 color="light-blue darken-3"
                                 depressed
                                 fab
                                 x-small
                                 v-on="on"
                                 @click="editItem(item)">
                            <v-icon color="white" small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>

                      <v-tooltip v-if="admin" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs"
                                 class="ma-1"
                                 color="teal"
                                 depressed
                                 fab
                                 x-small
                                 v-on="on"
                                 @click="item.webAccess ? showWebRevokeDialog(item) : showWebAccessDialog(item)">
                            <v-icon color="white">{{ !item.webAccess ? 'mdi-lock' : 'mdi-web' }}</v-icon>
                          </v-btn>

                        </template>
                        <span>Haga click para {{ !item.webAccess ? 'otorgar' : 'revocar' }} el acceso a la web</span>
                      </v-tooltip>

                      <v-tooltip v-if="admin" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs"
                                 class="ma-1"
                                 color="red"
                                 depressed
                                 fab
                                 x-small
                                 v-on="on"
                                 @click="showDeleteDialog(item)">
                            <v-icon color="white" small>mdi-delete</v-icon>
                          </v-btn>

                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </div>
                  </template>
                </if-auth>

              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex"
import EventBus from "@/util/eventbus"
import ConfirmationDialog from "@/components/dialog/ConfirmationDialog"
import IfAuth from "@/components/auth/IfAuth"

export default {
  name: "ClientList",
  components: {ConfirmationDialog, IfAuth},
  inject: ["clientRepository"],
  data: () => ({
    options: {},
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,
    dialogWebAccess: false,
    dialogWebRevoke: false,
    serverItemsLength: 0,
    loadingItems: false,
    filter: {
      page: 1,
      query: null,
    },
    editedItem: {
      identification: null,
      name: null,
      description: null,
      supervisor: null,
    },
    defaultItem: {
      identification: null,
      name: null,
      description: null,
      supervisor: null,
    },
    headers: [
      {
        text: "Identificación",
        value: "identification",
      },
      {
        text: "Nombre del cliente",
        value: "name",
      },
      {
        text: "Supervisor",
        value: "supervisor.name",
      },
      {
        text: "Acciones",
        value: "actions",
        width: 40 * 5 + 32,
      },
    ],
  }),
  computed: {
    ...mapState("client", ["clients"]),
  },
  watch: {
    options: {
      handler() {
        const {page, itemsPerPage} = this.options
        this.filter.PageSize = itemsPerPage
        this.filter.page = page
        this.fetchRecords(this.filter)
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    ...mapActions("client", ["fetchClients", 'deleteClient']),
    fetchRecords(query) {
      this.loadingItems = true
      this.fetchClients(query)
        .then((result) => {
          this.serverItemsLength = result
        })
        .finally(() => (this.loadingItems = false))
    },
    handleRefreshItem() {
      this.fetchRecords(this.filter)
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    viewClient(item) {
      this.$router.push({name: 'admin.client.view', params: {'id': item.id}})
    },
    editItem(item) {
      this.$router.push({name: 'admin.client.edit', params: {'id': item.id}}, () => {
      })
    },
    showDeleteDialog(item) {
      this.dialogDelete = true
      this.editedItem = item
    },
    closeDelete() {
      this.dialogDelete = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    deleteItem() {
      this.deleteClient(this.editedItem)
        .then(() => {
          this.serverItemsLength--
          this.closeDialogDelete()
          EventBus.$emit("SHOW_SNACKBAR", {
            text: 'Se ha eliminado el cliente',
            color: 'success',
          })
        })
    },
    viewReport(item) {
      window.open(`${process.env.VUE_APP_BASE_API}/report/${item.id}/excel`, "_blank")
    },
    showWebAccessDialog(item) {
      this.dialogWebAccess = true
      this.editedItem = item
    },
    showWebRevokeDialog(item) {
      this.dialogWebRevoke = true
      this.editedItem = item
    },
    grantWebAccess() {
      this.$store.commit("LOADER", true)
      this.clientRepository.grantWebAccess(this.editedItem.id)
        .then(() => {
          this.editedItem.webAccess = true
          this.closeDialogWebAccess()
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "Se ha realizado la operación con éxito",
            color: "success",
          })
        })
        .finally(() => {
          this.$store.commit("LOADER", false)
        })
    },
    revokeWebAccess() {
      this.$store.commit("LOADER", true)
      this.clientRepository.revokeWebAccess(this.editedItem.id)
        .then(() => {
          this.editedItem.webAccess = false
          this.closeDialogWebRevoke()
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "Se ha realizado la operación con éxito",
            color: "success",
          })
        })
        .finally(() => {
          this.$store.commit("LOADER", false)
        })
    },
    closeDialogWebAccess() {
      this.dialogWebAccess = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    closeDialogDelete() {
      this.dialogDelete = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    closeDialogWebRevoke() {
      this.dialogWebRevoke = false
      this.editedItem = Object.assign({}, this.defaultItem)
    }
  },
}
</script>

<style lang="sass" scoped>
.link
  cursor: pointer

.actions
  padding: 0
</style>
