var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-text-field',{attrs:{"prepend-inner-icon":'mdi-filter-variant',"append-icon":"mdi-magnify","clearable":"","hide-details":"","placeholder":"Aa...","solo":"","text":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchRecords(_vm.filter)},"click:clear":function($event){return _vm.fetchRecords(_vm.filter)}},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}}),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":_vm.handleRefreshItem}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('if-auth',{scopedSlots:_vm._u([{key:"allowed",fn:function(ref){
var admin = ref.admin;
return [(admin)?_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$router.push({name:'admin.client.create'})}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])})],1),_c('v-card-text',[_c('confirmation-dialog',{attrs:{"text":"¿Estás seguro de que quieres eliminar el cliente?","max-width":400},on:{"confirmed":_vm.deleteItem,"input":_vm.closeDialogDelete},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}}),_c('confirmation-dialog',{attrs:{"text":"¿Estás seguro de que quieres otorgar el acceso a este sitio web al cliente?","max-width":400},on:{"confirmed":_vm.grantWebAccess,"input":_vm.closeDialogWebAccess},model:{value:(_vm.dialogWebAccess),callback:function ($$v) {_vm.dialogWebAccess=$$v},expression:"dialogWebAccess"}}),_c('confirmation-dialog',{attrs:{"text":"¿Estás seguro de que quieres revocar el acceso a este sitio web al cliente?","max-width":400},on:{"confirmed":_vm.revokeWebAccess,"input":_vm.closeDialogWebRevoke},model:{value:(_vm.dialogWebRevoke),callback:function ($$v) {_vm.dialogWebRevoke=$$v},expression:"dialogWebRevoke"}}),_c('v-data-table',{attrs:{"footer-props":{
              showFirstLastPage: true,
              'items-per-page-options': [5, 10, 15, 50],
            },"headers":_vm.headers,"items":_vm.clients,"loading":_vm.loadingItems,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'admin.client.view', params:{id: item.id}}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.identification",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{name:'admin.client.view', params:{id: item.id}},"tag":"tr"}},[_vm._v(" "+_vm._s(item.identification)+" ")])]}},{key:"item.supervisor.name",fn:function(ref){
            var item = ref.item;
return [(item.supervisor)?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.supervisor.name)+" ")]):_c('div')]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('if-auth',{scopedSlots:_vm._u([{key:"allowed",fn:function(ref){
            var admin = ref.admin;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"green accent-5","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.viewReport(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-microsoft-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("Reporte de trabajos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"green accent-5","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.viewClient(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalles")])]),(admin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"light-blue darken-3","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(admin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"teal","depressed":"","fab":"","x-small":""},on:{"click":function($event){item.webAccess ? _vm.showWebRevokeDialog(item) : _vm.showWebAccessDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(!item.webAccess ? 'mdi-lock' : 'mdi-web'))])],1)]}}],null,true)},[_c('span',[_vm._v("Haga click para "+_vm._s(!item.webAccess ? 'otorgar' : 'revocar')+" el acceso a la web")])]):_vm._e(),(admin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"red","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()],1)]}}],null,true)})]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }